<template>
  <div class="minePage">
    <div class="headbox">
      <!-- 用户信息 -->
      <div class="userInfoBox">
        <!-- <img class="avatar" src="http://y.qichejiashi.com/tupian/upload/266892064.jpg" alt=""> -->
        <ImgDecypt
          :src="userInfo.avatarUrl"
          round
          class="avatar"
          :key="userInfo.avatarUrl"
        />
        <!-- <svg-icon v-else iconClass="place" class="imgLoading" /> -->
        <div class="userInfo">
          <div class="infoTop">
            <span>{{ userInfo.nickName }}</span>
            <svg-icon
              :iconClass="getVipSvg(userInfo.vipType)"
              class="vipIcon"
            ></svg-icon>
            <!-- <span>123</span> -->
          </div>
          <div class="user-box">
            <div class="userBottom">
              <span>ID: {{ userInfo.id }}</span>
              <div class="rightArrow"></div>
            </div>
            <div class="userBottom">
              <router-link tag="div" to="certificate" class="user-btn"
                >账号凭证
                <svg-icon iconClass="rightArrow" class="right_arrow"></svg-icon>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <!-- 我的权益 -->
      <div class="rightsBtn" @click="showPermiss">
        <div>我的权益</div>
        <svg-icon iconClass="downArrow"></svg-icon>
      </div>
      <!-- 免费观看次数 -->
      <div class="freeBox" v-if="userInfo.vipType === 0">
        <span
          >免费观看视频剩余{{ userInfo.leftWatchTimes || 0 }}/{{
            appConfig.logicCfg.ycWatchNumPerDay || 3
          }}</span
        >
        <div @click="toVip" class="vipBtn">开通会员</div>
      </div>
      <!-- 设置 -->
      <router-link tag="div" to="setUp">
        <svg-icon iconClass="setting" class="setting"></svg-icon>
      </router-link>
    </div>

    <!-- 数据 -->
    <div class="statisticsBox">
      <router-link tag="div" class="statistcs" to="/coupon">
        <div class="total">{{ getGoldVideoCoupon() }}</div>
        <div class="title">观影券</div>
      </router-link>
      <div class="splitLine"></div>
      <div class="statistcs">
        <div class="total">{{ userInfo.fanss | watchCount }}</div>
        <div class="title">粉丝</div>
      </div>
      <div class="splitLine"></div>
      <router-link tag="div" class="statistcs" to="/focusOn">
        <div class="total">{{ userInfo.cares | watchCount }}</div>
        <div class="title">关注</div>
      </router-link>
      <div class="splitLine"></div>
      <router-link tag="div" class="statistcs" to="/promote">
        <div class="total">{{ userInfo.invites | watchCount }}</div>
        <div class="title">推广</div>
      </router-link>
    </div>

    <!-- 会员 -->
    <div class="cardBox">
      <div @click="toVip" class="cardItem">
        <div class="cardTitle">
          <img src="@/assets/png/mine_vip.png" />
          <div>会员中心</div>
        </div>
        <div class="cardContent">{{ cardContent }}</div>
        <div class="btn" style="color: $orange">去开通</div>
      </div>
      <div @click="toWallet" class="cardItem">
        <div class="cardTitle">
          <img src="@/assets/png/mine_wallet.png" />
          <div>金币钱包</div>
        </div>
        <div class="cardContent">金币余额: {{ walletInfo.balance || 0 }}</div>
        <div class="btn">去充值</div>
      </div>
      <router-link tag="div" class="cardItem" to="/promote">
        <div class="cardTitle">
          <img src="@/assets/png/mine_promote.png" />
          <div>推广赚钱</div>
        </div>
        <div class="cardContent">当前收益: {{ walletInfo.income || 0 }}</div>
        <div class="btn">去赚钱</div>
      </router-link>
    </div>

    <!-- 观看历史 -->
    <div class="watchHistory" v-if="videoList.length">
      <div class="titleBox">
        <div class="title">观看历史</div>
        <router-link tag="div" to="watchHistory" class="more">
          <span>更多</span>
          <svg-icon iconClass="rightArrow" class="right_arrow"></svg-icon>
        </router-link>
      </div>
      <swiper
        ref="videoSwiper"
        :options="swiperOptions"
        class="videoSwiper"
        v-if="videoList.length > 0"
      >
        <swiper-slide
          v-for="(item, index) in videoList"
          :key="index"
          class="swiperSlide"
          @click.native="jumpVideoInfo(item)"
        >
          <div class="videoBox">
            <ImgDecypt class="videoBgImg" :src="item.videoCover" />
            <div class="title">{{ item.content }}</div>
            <CoveLabel class="cust_label" :item="item" />
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div class="advBox">
      <div
        class="advItem"
        v-for="(item, index) in gridAdvList"
        :key="index"
        @click="jumpAdd(item)"
      >
        <div class="advImgBox">
          <ImgDecypt :src="item.pictureUrl" :key="item.pictureUrl" />
        </div>
        <div class="advTitle">{{ item.name }}</div>
      </div>
    </div>
    <div class="interval"></div>
    <div class="funcTitle">常用功能</div>
    <div class="interval"></div>

    <van-grid column-num="4" :border="false" class="funcBox">
      <van-grid-item
        class="funcItem"
        use-slot
        v-for="(item, index) in funcList"
        :key="index"
        @click="goPage(item)"
      >
        <img :src="item.url" />
        <div>{{ item.title }}</div>
      </van-grid-item>
    </van-grid>

    <!-- 会员权益弹窗 -->
    <div class="vip-dialog-box" v-if="isRight">
      <div class="vip-dialog-wrapper" @click="hideRightDialog"></div>
      <div class="vip-dialog">
        <div class="vip-dialog-header">我的权益</div>
        <div class="vip-dialog-line"></div>
        <div class="vip-dialog-body">
          <div class="vipItem" v-if="vipType != 0">VIP权限视频无限观看</div>
          <div class="vipItem" v-if="checkTime(vipExpire)">
            VIP到期时间：{{ handleTime(vipExpire) }}
          </div>
          <div class="vipItem" v-if="checkTime(paidVideoFreeTimeExpire)">
            金币短视频免费观看：{{ handleTime(paidVideoFreeTimeExpire) }}
          </div>
          <div v-for="(item, index) in goldVideoDiscountRights" :key="index">
            <div class="vipItem" v-if="checkTime(item.expire) && index == 0">
              金币视频购买{{ (100 - item.discount) / 10 }}折：{{
                handleTime(item.expire)
              }}
            </div>
          </div>
          <div class="vipItem" v-if="louFengFreeCount">
            楼凤剩余免费解锁: {{ louFengFreeCount }} 次
          </div>
          <div
            v-for="(item, index) in louFengDiscountRights"
            :key="'lfDiscount' + index"
          >
            <div class="vipItem" v-if="checkTime(item.expire) && index == 0">
              {{
                item.discount == 100
                  ? "全场楼凤解锁免费"
                  : `全场楼凤解锁${(100 - item.discount) / 10}折：${handleTime(
                      item.expire
                    )}`
              }}
            </div>
          </div>
          <div
            v-for="(item, index) in louFengBookDiscountRights"
            :key="'lfBookDiscount' + index"
          >
            <div class="vipItem" v-if="checkTime(item.expire) && index == 0">
              {{
                item.discount == 100
                  ? "全场楼凤预约免费"
                  : `全场楼凤预约${(100 - item.discount) / 10}折：${handleTime(
                      item.expire
                    )}`
              }}
            </div>
          </div>
          <div
            v-for="(item, index) in louFengWeeklyUnlockRights"
            :key="'lfWeeklyUnlock' + index"
          >
            <div class="vipItem" v-if="checkTime(item.expire) && index == 0">
              楼凤每周解锁{{ item.count }}次：{{ handleTime(item.expire) }}
            </div>
          </div>
          <div
            v-for="(item, index) in videoTickets"
            :key="'videoTickets' + index"
          >
            <div class="vipItem">
              {{ `${item.gold}金币观影券：${item.count}张` }}
            </div>
          </div>
          <!-- <div v-if="userInfo.louFengDiscount">{{userInfo.louFengDiscount == 100 ? '全场楼凤解锁免费' : `全场楼凤${}折`}}</div> -->
          <!-- <div v-for="(item, index) in watchCoupons" :key="index">{{`${item.num}金币观影券：${item.count}`}}张</div> -->
        </div>
      </div>
    </div>
    <div class="aiUndress" @click="$router.push('/aiUndress')"></div>
    <!-- 提示 -->
    <div class="hint" v-if="isHint">您现在还不是会员，请先升级为会员哦</div>
    <van-dialog
      v-model="isActVoucher"
      title="防止账号丢失"
      show-cancel-button
      confirm-button-text="我要保存"
      cancel-button-text="我已保存"
      class="actVoucher"
      @cancel="cancel"
      @confirm="$router.push('certificate')"
    >
      <div class="line"></div>
      <p>方式一：请绑定手机号码，可通过账号切换输入手机号码找回账号。</p>
      <p>方式二：请保存账号凭证，通过扫描二维码找回账号。</p>
      <div class="mapt20"></div>
    </van-dialog>
  </div>
</template>

<script>
import { getCustom } from "@/api/index.js";
import { getUserInfo } from "@/api/user.js";
import { time } from "@/assets/js/filter.js";
import { queryHistoryVideo } from "@/utils/index.js";
import { jumpVideo } from "@/utils/index.js";
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import CoveLabel from "@/components/CoveLabel/index.vue";
import { getAdItem, AdType } from "@/utils/getAdv.js";
import { jumpAdv } from "@/utils/getAdv.js";
export default {
  name: "mine",
  components: {
    swiper,
    swiperSlide,
    CoveLabel,
  },
  data() {
    return {
      isActVoucher: false,
      videoList: [],
      // 观看历史轮播配置
      swiperOptions: {
        direction: "horizontal", // 横向切换选项
        slidesPerView: "auto", // 默认一个屏幕显示几张图
        spaceBetween: 10, // 间距
        observer: true, // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, // 修改swiper的父元素时，自动初始化swiper
      },
      // 功能列表
      funcList: [
        {
          title: "应用中心",
          url: require("../../assets/png/welfare.png"),
          path: "loufeng",
        },
        {
          title: "我的粉丝团",
          url: require("../../assets/png/mine_funs.png"),
          path: "fanClub",
        },
        {
          title: "官方社群",
          url: require("../../assets/png/mine_group.png"),
          type: "driverGroup",
        },
        {
          title: "在线客服",
          url: require("../../assets/png/mine_kefu.png"),
          type: "server",
        },
        {
          title: "我的色区",
          url: require("../../assets/png/mine_community.png"),
          path: "myCommunity",
        },
        {
          title: "我喜欢的",
          url: require("../../assets/png/mine_like.png"),
          path: "myLike",
        },
        {
          title: "已购视频",
          url: require("../../assets/png/mine_buy.png"),
          path: "purchasedVideo",
        },
        {
          title: "预约服务",
          url: require("../../assets/png/mine_reserve.png"),
          path: "appoinService",
        },
        {
          title: "我的裸聊",
          url: require("../../assets/png/mine_nudeChat.png"),
          path: "myNakedChat",
        },
        // {
        //   title: '我的发布',
        //   url: require('../../assets/png/mine_nudeChat.png'),
        //   path: 'myNakedChat'
        // },
      ],
      // 权益弹窗
      isRight: false,
      vipType: "", // vip类型
      vipExpire: "", // vip过期时间
      louFengFreeCount: "", // 楼凤免费次数
      paidVideoFreeTimeExpire: "", // 金币视频免费观看时间
      goldVideoDiscountRights: [], //金币视频折扣
      louFengDiscountRights: [], // 楼凤解锁折扣
      louFengBookDiscountRights: [], // 楼凤预约折扣
      louFengWeeklyUnlockRights: [], // 楼凤每周解锁次数
      videoTickets: [], // 观影券
      // rightsList: [], // 权益列表
      // videoDiscount: "", // 金币视频折扣
      // vipExpireDate: "", // vip过期时间
      isHint: false, // 提示弹窗
      userInfo: {}, // 用户信息
      appConfig: {}, // 配置
      walletInfo: {}, // 钱包信息

      cardContent: "未开通VIP",
      gridAdvList: [],
    };
  },
  async created() {
    this.getUserInfo();
    this.getWalletInfo();
    this.appConfig = JSON.parse(sessionStorage.getItem("appConfig"));
    this.videoList = await queryHistoryVideo(1, 10);
    let flag = localStorage.getItem("isActVoucher");
    if (!flag) {
      this.isActVoucher = true;
    }
    this.gridAdvList = getAdItem(AdType.mineAdv).slice(0, 10);
  },
  methods: {
    toVip() {
      let vipGrap = {
        type: "user",
      };
      sessionStorage.setItem("vipGrap", JSON.stringify(vipGrap));
      this.$router.push("/vipList");
    },
    toWallet() {
      let vipGrap = {
        type: "user",
      };
      sessionStorage.setItem("vipGrap", JSON.stringify(vipGrap));
      this.$router.push("/wallet");
    },
    async jumpVideoInfo(item) {
      jumpVideo(item);
    },
    cancel() {
      localStorage.setItem("isActVoucher", true);
    },
    // 获取用户信息
    async getUserInfo() {
      let ret = await this.$Api(getUserInfo, undefined);
      if (ret && ret.code == 200) {
        sessionStorage.setItem("userInfo", JSON.stringify(ret.data.info)); //存储自己的用户信息
        this.userInfo = ret.data.info;
        this.vipType = ret.data.info.vipType;
        this.vipExpire = ret.data.info.vipExpire * 1000;
        this.louFengFreeCount = ret.data.info.louFengFreeCount;
        this.paidVideoFreeTimeExpire = ret.data.info.paidVideoFreeTimeExpire;
        this.goldVideoDiscountRights =
          ret.data.info.goldVideoDiscountRights ?? [];
        this.louFengDiscountRights = ret.data.info.louFengDiscountRights ?? [];
        this.louFengBookDiscountRights =
          ret.data.louFengBookDiscountRights ?? [];
        this.louFengWeeklyUnlockRights =
          ret.data.louFengWeeklyUnlockRights ?? [];

        if (this.userInfo.vipType != 0) {
          this.cardContent = `有效期:${this.fmtTime(this.vipExpire)}`;
        }
      }
    },
    fmtTime(da) {
      let date = new Date(da);

      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      let d = date.getDate();
      if (m < 10) {
        m = "0" + m;
      }
      if (d < 10) {
        d = "0" + d;
      }
      return `${y}-${m}-${d}`;
    },
    // 获取钱包信息
    async getWalletInfo() {
      await this.$store.dispatch("user/setWallet");
      this.walletInfo = this.$store.getters.walletInfo;
    },

    // 获取观影券总数
    getGoldVideoCoupon() {
      let videoTickets = 0;
      // console.log(this.userInfo.goldVideoCoupon, "++++++++++")
      if (this.userInfo.videoTickets && this.userInfo.videoTickets.length > 0) {
        this.userInfo.videoTickets.forEach((i) => {
          videoTickets += i.count;
        });
      }
      return videoTickets;
    },

    // 获取vip图标
    getVipSvg(type) {
      let svg = "";
      switch (type) {
        case 1:
          svg = "experienceCard";
          break;
        case 2:
          svg = "monthlyCard";
          break;
        case 3:
          svg = "seasonCard";
          break;
        case 4:
          svg = "permanentCard";
          break;
        default:
          break;
      }
      return svg;
    },
    jumpAdd(item) {
      jumpAdv(item);
    },

    // 隐藏权益弹窗
    hideRightDialog() {
      this.isRight = false;
    },
    // 校验时间
    checkTime(timer) {
      let todayTime = new Date().getTime();
      let effectiveTime = new Date(timer).getTime();
      if (timer && todayTime < effectiveTime) {
        return true;
      }
      return false;
    },
    // 处理时间
    handleTime(timer) {
      let str;
      let maxDate = new Date("2030-01-01").getTime();
      let vipExpireDate = new Date(timer).getTime();
      if (vipExpireDate > maxDate) {
        str = "永久";
      } else {
        str = time(timer);
      }
      return str;
    },
    // 点击我的权益
    showPermiss() {
      if (
        this.checkTime(this.vipExpire) ||
        this.checkTime(this.paidVideoFreeTimeExpire) ||
        this.louFengFreeCount ||
        this.vipType != 0 ||
        this.goldVideoDiscountRights.length > 0 ||
        this.louFengDiscountRights.length > 0 ||
        this.louFengBookDiscountRights.length > 0 ||
        this.louFengWeeklyUnlockRights.length > 0 ||
        this.videoTickets.length > 0
      ) {
        this.isRight = true;
      } else {
        this.$toast("您现在还不是会员，请先升级为会员哦");
      }
    },
    // 页面跳转
    goPage(item) {
      if (item.path) {
        this.$router.push(item.path);
        return;
      }
      if (item.type == "driverGroup") {
        window.location = this.appConfig.logicCfg.driverGroup;
        return;
      }
      if (item.type == "server") {
        this.server();
        return;
      }
    },
    async server() {
      let toast = this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      let ret = await this.$Api(getCustom);
      toast.clear();
      if (ret.code == 200) {
        let endpoint = ret.data.endpoint;
        location = this.$store.state.app.baseUrl + endpoint;
        return;
      }
      this.$toast(ret.tip || "加载客服失败");
    },
  },
};
</script>

<style lang="scss" scoped>
.mapt20 {
  margin-top: 20px;
}

.line {
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, transparent, #ccc, transparent);
  margin-bottom: 10px;
}

.actVoucher {
  padding: 20px 10px 10px;
  font-size: 14px;

  p {
    padding: 10px;
  }

  /deep/ .van-dialog__header {
    padding: 0;
    font-size: 24px;
    padding-bottom: 10px;
  }
}

.user-box {
  display: flex;

  .user-btn {
    margin-left: 10px;
    color: $black1d;
    font-size: 10px;
    width: 64px;
    height: 19px;
    border-radius: 11px;
    text-align: center;
    line-height: 19px;
    background-image: linear-gradient(
      to right,
      $lightMustard 20%,
      $brightOrange 100%
    );
    font-weight: 400;
  }
}

//图标
.cust_label {
  position: absolute;
  top: 0;
  right: 0;
}

.minePage {
  height: 100%;
  overflow: auto;
  -ms-overflow-style: none;
  // padding-bottom: 70px;
  // background: $whiteTow;
}

.minePage::-webkit-scrollbar {
  width: 0 !important;
}

.headbox {
  background: $purpleBrown;
  position: relative;
  padding: 33px 16px 9px;

  .userInfoBox {
    display: flex;
    color: $white1;

    .userInfo {
      padding: 4px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .infoTop {
        display: flex;
        align-items: center;
        font-size: 20px;
        font-weight: 600;

        span {
          margin-right: 7px;
        }

        .vipIcon {
          width: 40px;
        }
      }

      .userBottom {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: $veryLightPink;

        .rightArrow {
          width: 8px;
          height: 8px;
          border-top: 1px solid $veryLightPink;
          border-right: 1px solid $veryLightPink;
          transform: rotate(45deg);
        }
      }
    }

    .avatar {
      width: 64px;
      height: 64px;
      border-radius: 64px;
      background-size: cover;
      margin-right: 18px;
    }

    .imgLoading {
      width: 64px;
      height: 64px;
      border-radius: 64px;
      background-size: cover;
      margin-right: 18px;
      background: #ededed;
    }
  }

  .rightsBtn {
    width: 81px;
    height: 22px;
    text-align: center;
    background: $beige;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $black;
    font-size: 14px;
    margin-top: 13px;
    border-radius: 4px;
    padding: 0 6px;

    .downArrow {
      width: 7px;
      height: 7px;
      border-top: 1.8px solid $black;
      border-right: 1.8px solid $black;
      transform: rotate(135deg);
    }
  }

  .freeBox {
    color: $white1;
    background-image: linear-gradient(to right, $vermillion, $grapefruit);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 0 15px;
    width: 343px;
    height: 28px;
    box-sizing: border-box;
    border-radius: 13px;
    font-size: 12px;
    color: $white1;
    margin-top: 9px;
    font-weight: 600;

    .vipBtn {
      color: $black1d;
      font-size: 10px;
      width: 64px;
      height: 19px;
      border-radius: 11px;
      text-align: center;
      line-height: 19px;
      background-image: linear-gradient(
        to right,
        $lightMustard 20%,
        $brightOrange 100%
      );
      font-weight: 400;
    }
  }

  .setting {
    position: absolute;
    top: 14px;
    right: 18px;
    color: $white1;
    width: 26px;
    height: 26px;
  }
}

.statisticsBox {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  width: 320px;
  height: 41px;
  margin: 13px auto 10px;

  .statistcs {
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    // height: 100%;
    .total {
      font-size: 18px;
      font-weight: 600;
    }

    .title {
      font-size: 10px;
      color: $warmGrey;
      margin-top: 2px;
    }
  }

  .splitLine {
    width: 1px;
    height: 16px;
    background: $black;
  }
}

.cardBox {
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  margin-bottom: 14px;
  color: $white1;

  .cardItem {
    width: 105px;
    height: 80px;

    .cardTitle {
      display: flex;
      align-items: center;
      margin-left: 8px;
      font-size: 13px;
      margin-top: 10px;

      img {
        margin-right: 4.3px;
        height: 18px;
      }
    }

    .cardContent {
      font-size: 8px;
      margin: 6px 0 0 8px;
    }

    .btn {
      font-size: 8px;
      width: 34px;
      height: 13px;
      margin: 11px 0 0 11px;
      text-align: center;
      line-height: 13px;
      border-radius: 8px;
      background: $white1;
    }
  }

  .cardItem:nth-child(1) {
    background: url("../../assets/png/mine_vipBg.png");
    background-size: cover;

    .btn {
      color: $orange;
    }
  }

  .cardItem:nth-child(2) {
    background: url("../../assets/png/mine_walletBg.png");
    background-size: cover;

    .btn {
      color: $warmPink;
    }
  }

  .cardItem:nth-child(3) {
    background: url("../../assets/png/mine_promoteBg.png");
    background-size: cover;

    .btn {
      color: $mediumPink;
    }
  }
}

.watchHistory {
  margin: 0 10px 8px 0;

  .titleBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px 0 16px;

    .title {
      font-size: 14px;
      font-weight: 600;
    }

    .more {
      font-size: 10px;
      color: $warmGrey;
      display: flex;

      .right_arrow {
        margin-left: 2px;
      }
    }
  }
}

.videoSwiper {
  margin: 12px 6px 0 16px;

  .videoBox {
    position: relative;
  }

  .swiperSlide {
    width: 141px;

    .videoBgImg {
      width: 100%;
      height: 86px;
      border-radius: 5px;

      /deep/ img {
        border-radius: 5px;
      }
    }

    .title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-top: 6px;
    }
  }
}

.interval {
  width: 100%;
  height: 5px;
  background: $whiteTow;
}

.funcTitle {
  height: 30px;
  line-height: 30px;
  margin-left: 16px;
  font-size: 14px;
}

.funcBox {
  margin-top: 24px;
  padding: 0 16px;
  color: $blackTow;
  margin-bottom: 70px;

  .funcItem {
    img {
      width: 24px;
      height: 24px;
      // margin: 0 auto;
    }

    div {
      margin-top: 3px;
      font-size: 10px;
    }
  }
}

.vip-dialog-box {
  .vip-dialog-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 2;
  }

  /* 会员权益弹窗 */
  .vip-dialog {
    width: 246px;
    min-height: 100px;
    border-radius: 10px;
    padding: 7px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    z-index: 3;

    .vip-dialog-header {
      text-align: center;
      color: $black1d;
      font-size: 18px;
    }

    .vip-dialog-line {
      margin-top: 3px;
      margin-bottom: 5px;
      height: 2px;
      background-image: linear-gradient(
        116deg,
        rgba(255, 255, 255, 0) 20%,
        $orangeTow 49%,
        rgba(255, 255, 255, 0) 80%
      );
    }

    .vip-dialog-body {
      .vipItem {
        margin-top: 5px;
      }
    }
  }
}
.aiUndress {
  height: 65px;
  width: 65px;
  background: url("./../../assets/png/aiUndress.png") no-repeat;
  background-size: 100% 100%;
  position: fixed;
  right: 15px;
  bottom: 70px;
  z-index: 999;
}

.hint {
  width: 70%;
  font-size: 14px;
  color: $white1;
  // height: 60px;
  // line-height: 60px;
  padding: 10px;
  text-align: center;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 10;
  white-space: nowrap;
  transform: translate(-50%, -50%);
  border-radius: 5px;
}
.advBox {
  padding: 12px 16px 0;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  // margin-top: -59px;
  grid-gap: 11px;
  .advItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    .advImgBox {
      width: 60px;
      height: 60px;
      border-radius: 12px;
      overflow: hidden;
    }
    .advTitle {
      margin-top: 4px;
      font-size: 12px;
      color: #333;
      width: 60px;
      overflow: hidden; //超出的文本隐藏
      text-overflow: ellipsis; //溢出用省略号显示
      white-space: nowrap; // 默认不换行；
      text-align: center;
    }
  }
}
</style>
